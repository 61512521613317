import React from "react"
import { graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import ContentContainer from "../../../components/contentContainer"
import OneProgramTileComponent from "../../../components/oneProgramTileComponent"
import MultipleProgramTileComponent from "../../../components/multipleProgramTileComponent"
import Rocket from "../../../components/rocket"
import Loader from "../../../components/loader"
import { CURRENT_KEY_JSC, getPresentationsBySlot, getProgramData } from "../../../helpers/apiService"

class Program extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      error: false,
      loading: true,
    }
  }

  componentDidMount() {
    getProgramData(CURRENT_KEY_JSC).then(
      response => {
        if (response.ok) {
          response.json().then(lineup => {
            const parsedData = getPresentationsBySlot(lineup)
            this.setState({ data: parsedData, error: false, loading: false })
          })
        } else {
          this.setState({ error: true, loading: false })
        }
      },
      () => {
        this.setState({ error: true, loading: false })
      })
  }

  render() {
    const { error, loading, data } = this.state
    const programmSeo = this.props.data.allMarkdownRemark.edges[0].node
    const siteTitle = this.props.data.site.siteMetadata.title
    const presentationsPerSlot = Object.values(data)
    let tracksShown = false
    return (

      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={programmSeo.frontmatter.seoTitle || programmSeo.frontmatter.title}
             description={programmSeo.frontmatter.seoDescription} />
        <div className="content">
          <ContentContainer paddingTop>
            <Rocket />
            <div>
              {error &&
                <div className="notification">
                  Aktuell steht (noch) kein Programm zur Verfügung.
                </div>
              }
            </div>
            <div>
              {loading && <Loader />}
            </div>
            <div>
              {(!error && !loading) &&
                presentationsPerSlot.map((presentations, index) => {
                  if (!tracksShown && presentations.length > 1) {
                    tracksShown = true
                    return (
                      <div key={100 + index}>
                        <MultipleProgramTileComponent showsTracks={true} presentations={presentations}
                                                      index={index} detailLinkLocation={"/camp/programm/details"} />
                        <MultipleProgramTileComponent showsTracks={false} presentations={presentations}
                                                      index={index + 1} detailLinkLocation={"/camp/programm/details"} />
                      </div>
                    )
                  } else if (presentations.length === 1) {
                    const presentation = presentations[0]
                    return <OneProgramTileComponent key={200 + index} presentation={presentation} index={index}
                                                    detailLinkLocation={"/camp/programm/details"} />

                  } else {
                    return <MultipleProgramTileComponent key={300 + index} showsTracks={false}
                                                         presentations={presentations} index={index}
                                                         detailLinkLocation={"/camp/programm/details"} />
                  }
                })
              }
            </div>
          </ContentContainer>
        </div>
      </Layout>
    )
  }
}

export default Program

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/camp/programm.md/"}}) {
      edges {
        node {
          frontmatter {
            title
            seoTitle
            seoDescription
          }
        }
      }
    }
  }
`
